import React, {useState} from "react";
import {
    XBrowseProps,
    XLazyColumn,
    XLazyDataTable,
    XOptionalCustomFilter,
    XSearchBrowseProps
} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {Utils} from "../../Utils";
import {KlientSluzbaForm} from "./KlientSluzbaForm";
import {HladatKlientaMimoSluzbuDialog, HladatKlientaMimoSluzbuDialogState} from "./HladatKlientaMimoSluzbuDialog";
import {Klient} from "../../model/klient/klient.entity";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {xLocaleOption} from "@michalrakus/x-react-web-lib/XLocale";
import {KlientSluzba} from "../../model/klient/klient-sluzba.entity";
import {ColumnBodyOptions} from "primereact/column";
import {AsUIType, convertValueBase, XDateScale} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {EnumEnum, NoclaharenPlatbaEnum, SluzbaEnum} from "../../common/enums";

const whereAktivniZaPoslednychXDniNoclaharen: string =
    `[id] IN (SELECT ks.id FROM ${Utils.getSchema()}.klient_sluzba ks WHERE ks.modif_date >= :datumMinusXDni`
    + ` UNION`
    + ` SELECT u.klient_sluzba_id FROM ${Utils.getSchema()}.ubytovanie u WHERE u.modif_date >= :datumMinusXDni)`;

const optionalCustomFiltersNoclaharen: XOptionalCustomFilter[] = [
    {
        label: "Práve ubytovaní",
        filter: {where: `[id] IN (SELECT klient_sluzba_id FROM ${Utils.getSchema()}.ubytovanie WHERE datum = :datum)`, params: {datum: Utils.todayNoclaharen()}}
    },
    {
        label: "Nezaplatení",
        filter: {where: `[id] IN (SELECT klient_sluzba_id FROM ${Utils.getSchema()}.ubytovanie WHERE datum = :datum AND platba_id = ${NoclaharenPlatbaEnum.Nezaplatil.id})`, params: {datum: Utils.todayNoclaharen()}}
    },
    {
        label: "Aktívni za posledný týždeň",
        filter: {where: whereAktivniZaPoslednychXDniNoclaharen, params: {datumMinusXDni: XUtilsCommon.dateAddDays(Utils.todayNoclaharen(), -7)}}
    },
    {
        label: "Aktívni za posledné 2 týždne",
        filter: {where: whereAktivniZaPoslednychXDniNoclaharen, params: {datumMinusXDni: XUtilsCommon.dateAddDays(Utils.todayNoclaharen(), -14)}}
    }
];

export const KlientSluzbaBrowse = (props: XBrowseProps & XSearchBrowseProps) => {

    const [hladatKlientaDialogState, setHladatKlientaDialogState] = useState<HladatKlientaMimoSluzbuDialogState>({opened: false});

    const today: Date = XUtilsCommon.today();

    const onAddRow = () => {

        if (!Utils.getCurrentSluzba()) {
            alert("Vyberte najprv službu.");
            return;
        }

        setHladatKlientaDialogState({
            opened: true,
            onHideDialog: onHideDialog
        });
    }

    const onHideDialog = async (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => {
        // TODO - objectChange nie je spraveny, zatial nepouzivat
        setHladatKlientaDialogState({opened: false});
        if (ok) {
            if (klient !== null) {
                // TODO - zatial vezmeme priamo cely zaznam Klient a pouzijeme ho vo formulari KlientSluzbaForm pre update
                // -> korektnejsie by bolo teraz rereadnut zaznam z DB (pouzit klient!.id)
                // potrebujeme najoinovat vsetko co sa pouziva vo formulari KlientSluzbaForm v casti klient (cast KlientSluzba bude insertovana) (v autocomplete zobrazujeme aj obec aj okres)
                const klientFromDB: Klient = await XUtils.fetchById("Klient", ["pohlavie.name", "obec.okres.nazov", "stat.nazov", "klientSluzbaList.sluzba.nazov"], klient!.id);
                // otvorime formular KlientForm v rezime na insert (id zaznamu KlientSluzba je undefined) a predplnime niektore atributy
                props.openForm!(<KlientSluzbaForm initValues={{klient: klientFromDB, sluzba: Utils.getCurrentSluzba(), version: 0}}/>);
            }
            else {
                // otvorime formular KlientForm v rezime na insert, pricom aj klient bude nanovo vytvoreny
                // inicializacia prebehne cez KlientSluzbaForm.createNewObject()
                props.openForm!(<KlientSluzbaForm/>);
            }
        }
    }

    const onEdit = (selectedRow: any) => {

        // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu
        props.openForm!(<KlientSluzbaForm id={selectedRow.id}/>);
    }

    const onRemoveRow = async (selectedRow: any): Promise<boolean> =>  {

        if (window.confirm(xLocaleOption('removeRowConfirm'))) {
            await XUtils.post('remove-klient-sluzba', {id: selectedRow.id});
            return true;
        }
        return false;
    }

    const rowClassName = (rowData: KlientSluzba) => {
        return {
            'klient-sluzba-zakaz': Utils.klientSluzbaMaZakaz(rowData, today),
            'klient-sluzba-zosnuly': Utils.klientSluzbaZosnuly(rowData),
            'klient-sluzba-neaktivny': Utils.klientSluzbaNeaktivnyOrNieJeKlient(rowData)
        };
    };

    const klientDatumNarodeniaBody = (klientSluzba: KlientSluzba, options: ColumnBodyOptions): React.ReactNode => {
        return convertValueBase('date', klientSluzba.klient.datumNarodeniaIbaRok ? XDateScale.Year : XDateScale.Date, klientSluzba.klient.datumNarodenia, true, AsUIType.Form);
    }

    const sluzbaEnum: SluzbaEnum | undefined = Utils.getCurrentSluzba()?.kod as SluzbaEnum;

    let optionalCustomFilters: XOptionalCustomFilter[] | undefined = undefined;
    if (sluzbaEnum === SluzbaEnum.noclaharen) {
        optionalCustomFilters = optionalCustomFiltersNoclaharen;
    }

    // neviem uplne presne, ake stlpce pre aku sluzbu maju byt zobrazene (ak to nie su specificke stlpce ako Cislo vreca pre noclaharen)
    // otazne je napr. rodneCislo, ktore bolo v povodnom browse noclaharne, tak som ho dal len do noclaharne (zatial)
    return (
        <div>
            <XLazyDataTable entity="KlientSluzba" stateKey={`klient-sluzba-browse-${sluzbaEnum}`}
                            label="Klienti" sortField="id desc" rows={30} customFilter={Utils.filterCurrentSluzba()}
                            onAddRow={onAddRow} onEdit={onEdit} removeRow={onRemoveRow}
                            fields={["klientSluzbaZakazList.id", "statusVSluzbe.code"]} rowClassName={rowClassName}
                            optionalCustomFilters={optionalCustomFilters}
                            displayed={props.displayed} searchBrowseParams={props.searchBrowseParams}>
                <XLazyColumn field="id" header="ID" width="5rem" columnViewStatus={Utils.isUserAdmin()}/>
                <XLazyColumn field="klient.menoPriezviskoPrezyvka" header="Meno priezvisko prezývka" width="14rem"/>
                <XLazyColumn field="klient.datumNarodenia" header="Dátum narodenia" body={klientDatumNarodeniaBody}/>
                <XLazyColumn field="klient.rodneCislo" header="Rodné číslo" width="7rem" columnViewStatus={sluzbaEnum === SluzbaEnum.noclaharen}/>
                <XLazyColumn field="klient.ulica" header="Ulica" width="10rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork}/>
                <XLazyColumn field="klient.cisloDomu" header="Číslo domu" width="7rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork}/>
                <XLazyColumn field="klient.psc" header="PSČ" width="5rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork}/>
                <XLazyColumn field="klient.obecText" header="Mesto/obec (text)" width="11rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork}/>
                <XLazyColumn field="klient.obec.nazov" header="Mesto/obec" width="10rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork}/>
                <XLazyColumn field="klient.obec.okres.nazov" header="Okres" width="10rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork} autoComplete={true}/>
                <XLazyColumn field="klient.stat.nazov" header="Štát" width="8rem" columnViewStatus={sluzbaEnum !== SluzbaEnum.streetwork} autoComplete={true}/>
                <XLazyColumn field="klucovyPracovnikXUser.name" header="Kľúčový pracovník" width="12rem" columnViewStatus={Utils.isSluzbaSocPorad()}/>
                <XLazyColumn field="datumPoslKontaktu" header="Posl. kontakt" width="8.3rem" columnViewStatus={Utils.isSluzbaSocPorad()}/>
                <XLazyColumn field="miestoZdrziavaniaMestskaCast.name" header="Mestská časť" width="8rem" columnViewStatus={sluzbaEnum === SluzbaEnum.streetwork}/>
                <XLazyColumn field="lujzaPoradProjekt.name" header="Projekt" width="9rem" columnViewStatus={sluzbaEnum === SluzbaEnum.lujzaPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.lujzaPoradProjekt)}/>
                <XLazyColumn field="vincentPoradProjekt.name" header="Projekt" width="10rem" columnViewStatus={sluzbaEnum === SluzbaEnum.vincentPoradenstvo} dropdownInFilter={true} dropdownFilter={Utils.enumFilter(EnumEnum.vincentPoradProjekt)}/>
                <XLazyColumn field="cisloVreca" header="Číslo vreca" width="7rem" columnViewStatus={sluzbaEnum === SluzbaEnum.noclaharen}/>
                <XLazyColumn field="predplateneNoci" header="Predpl. noci" width="7rem" columnViewStatus={sluzbaEnum === SluzbaEnum.noclaharen}/>
            </XLazyDataTable>
            <HladatKlientaMimoSluzbuDialog dialogState={hladatKlientaDialogState}/>
        </div>
    );
}
