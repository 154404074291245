import React, {useState} from "react";
import {Dialog} from "primereact/dialog";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {Klient} from "../../model/klient/klient.entity";
import {OperationType, XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XAutoCompleteBase} from "@michalrakus/x-react-web-lib/XAutoCompleteBase";
import {Utils} from "../../Utils";
import {KlientBrowse} from "./KlientBrowse";
import {KlientForm} from "./KlientForm";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";

// ak user stlaci ok, vrati dvojicku (klient, objectChange) - vysledok AutoComplete-u

export interface HladatKlientaMimoSluzbuDialogState {
    opened: boolean;
    // datumOd?: Date | null;
    // datumDo?: Date | null;
    onHideDialog?: (ok: boolean, klient?: Klient | null, objectChange?: OperationType) => void;
}

export const HladatKlientaMimoSluzbuDialog = (props: {
    dialogState: HladatKlientaMimoSluzbuDialogState;
}) => {

    const [klient, setKlient] = useState<Klient | null>(null);
    // const [datumDo, setDatumDo] = useState<Date | null>(null);

    let klientError: string | undefined = undefined;

    // bez tejto metody by pri opetovnom otvoreni dialogu ponechal povodne hodnoty
    const onShow = () => {

        setKlient(null);
        //setDatumDo(props.dialogState.datumDo ?? null);
    }

    const onPouzitKlienta = () => {

        if (klientError) {
            alert(klientError);
            return;
        }

        if (!klient) {
            alert("Klient musí byť zadaný.");
            return;
        }

        props.dialogState.onHideDialog!(true, klient, OperationType.None);
    }

    const onVytvoritKlienta = () => {

        props.dialogState.onHideDialog!(true, null, OperationType.None);
    }

    const onHide = () => {
        // onHide sa zavola aj ked zbehnu metody onPouzitKlienta/onVytvoritKlienta a vtedy uz nemame props.dialogState.onHideDialog
        if (props.dialogState.onHideDialog) {
            props.dialogState.onHideDialog(false);
        }
    }

    // na sirku pouzijeme podobny sposob ako v XFormScrollable ale riesime len width a maxWidth sme nahradili width
    // (po spravnosti by to asi malo zostat maxWidth a obsah dialogu (autocomplete) by mal roztahovat dialog - sirka by mala byt nie 100% ale nejaka vyratana pomocou XUtils.getViewWidth())
    const marginsWidth: number = XUtils.toPX0(XUtils.isMobile() ? '1rem' : '2.6rem');
    const width: string = `min(calc(100vw - ${marginsWidth}px), 60rem)`; // dame na celu sirku obrazovky, ak je moc siroke, obmedzime na 60rem

    return (
        <Dialog visible={props.dialogState.opened} position="top" onShow={onShow} onHide={onHide} className="x-dialog-without-header">
            <div style={{width: width}}>
                <XFormHeader label={`Klienti z ostatných služieb (${Utils.isSluzbaNoclaharen() ? "ošetrovňa" : "nocľaháreň"}, útulky, ...)`}/>
                <div className="field grid">
                    <label className="col-fixed" style={{width:'3.5rem'}}>Klient</label>
                    <XAutoCompleteBase value={klient} onChange={(klient: Klient | null, objectChange: OperationType) => setKlient(klient)}
                                       onErrorChange={(error: string | undefined) => klientError = error} idField="id"
                                       field={["meno", "priezvisko", "prezyvka", "pohlavie.name", "datumNarodenia", "ulica", "cisloDomu", "psc", "obecText", "obec.nazov", "stat.nazov", "ID:id"]}
                                       suggestionsQuery={{entity: "Klient", filter: Utils.klientFilterCurrentSluzba(true)}}
                                       searchBrowse={<KlientBrowse label="Klienti z ostatných služieb"/>} width="calc(100% - 3.5rem)" scrollHeight="50rem"
                                       suggestionsLoad="lazy" lazyLoadMaxRows={15} minLength={3}/>
                </div>
                <div className="flex justify-content-center">
                    <XButton key="pouzit-najdeneho" label="Použiť nájdeného klienta" onClick={onPouzitKlienta}/>
                    <XButton key="vytvorit" label="Vytvoriť nového klienta" onClick={onVytvoritKlienta}/>
                </div>
            </div>
        </Dialog>
    );
}
