import React from "react";
import {Form} from "../../XLibItems";
import {XInputDecimal} from "@michalrakus/x-react-web-lib/XInputDecimal";
import {XInputText} from "@michalrakus/x-react-web-lib/XInputText";
import {XFormFooter} from "@michalrakus/x-react-web-lib/XFormFooter";
import {XInputDate} from "@michalrakus/x-react-web-lib/XInputDate";
import {XAutoComplete} from "@michalrakus/x-react-web-lib/XAutoComplete";
import {Utils} from "../../Utils";
import {EnumEnum, SluzbaEnum} from "../../common/enums";
import {XFormAutoCompleteColumn, XFormColumn, XFormDataTable2} from "@michalrakus/x-react-web-lib/XFormDataTable2";
import {VykazStreetwork} from "../../model/zapisy/vykaz-streetwork.entity";
import {Zapis} from "../../model/zapisy/zapis.entity";
import type {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {VykazXUser} from "../../model/zapisy/vykaz-x-user.entity";
import {XErrors} from "@michalrakus/x-react-web-lib/XErrors";
import {FindUsersRequest} from "../../common/user-api";
import {MiestoMoznehoKontaktuBrowse} from "./MiestoMoznehoKontaktuBrowse";
import {MiestoMoznehoKontaktuForm} from "./MiestoMoznehoKontaktuForm";
import {ZapisBaseForm} from "./ZapisBaseForm";
import {XEditor} from "@michalrakus/x-react-web-lib/XEditor";
import {XFormHeader} from "@michalrakus/x-react-web-lib/XFormHeader";
import {XFormScrollable} from "../XFormScrollable";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";
import {dateAsUI, dateFromModel} from "@michalrakus/x-react-web-lib/XUtilsConversions";
import {XObject} from "@michalrakus/x-react-web-lib/XObject";
import {XButton} from "@michalrakus/x-react-web-lib/XButton";
import {XBrowseProps} from "@michalrakus/x-react-web-lib/XLazyDataTable";
import {ZapisBrowse} from "./ZapisBrowse";
import {DataTableFilterMeta} from "primereact/datatable";
import {FilterMatchMode} from "primereact/api";
import {XEnum} from "../../model/user/x-enum.entity";
import {MiestoMoznehoKontaktu} from "../../model/zapisy/miesto-mozneho-kontaktu.entity";

// novy formular - iba hlavicka bez zapisov
// vytvorene kopirovanim z VykazStreetworkForm
// bolo by pekne zjednotit spolocny kod ale VykazStreetworkForm mozno casom uplne zahodime
@Form("VykazStreetwork")
export class VykazStreetworkHlavickaForm extends ZapisBaseForm {

    userStreetworkList: XUserDePaul[] = [];

    constructor(props: XFormProps) {
        super(props);

        // nacitame aj zapisy, ak existuju, tak datum bude readOnly (efektivnejsie by asi bolo nacitat len pocet v componentDidMount())
        this.addField("zapisList.id");

        this.removeVykazXUser = this.removeVykazXUser.bind(this);
    }

    async componentDidMount() {
        await super.componentDidMount();

        // nacitame si zoznam userov pre stretwork (tych budeme ponukat v autocomplete v casti Pracovnici)
        this.userStreetworkList = await XUtils.fetchMany('find-users', {sluzbaKod: SluzbaEnum.streetwork} satisfies FindUsersRequest);

        this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    createNewObject(): XObject {
        return {
            datum: XUtilsCommon.today(),
            vykazXUserList: [], vykazMestskaCastList: [], vykazMiestoMoznehoKontaktuList: [], zapisList: [],
            version: 0
        };
    }

    // pomocna metodka
    private getVykazStreetwork(): VykazStreetwork | null {
        return this.state.object;
    }

    // pomocna metodka
    // private getZapisList(): Zapis[] {
    //     const vykazStreetwork: VykazStreetwork = this.getXObject() as VykazStreetwork;
    //     return vykazStreetwork.zapisList;
    // }

    readOnlyDatum(object: VykazStreetwork): boolean {
        return !(object && object.zapisList.length === 0);
    }

    itemTemplateMiestoMoznehoKontaktu(suggestion: MiestoMoznehoKontaktu, index: number, createStringValue: boolean, defaultValue: (suggestion: any) => string): React.ReactNode {
        if (createStringValue) {
            // hodnota do inputu - iba miesto
            return suggestion.miesto;
        }
        else {
            // hodnota do dropdown panelu - dame miesto + mestska cast
            return [suggestion.miesto, suggestion.mestskaCast?.name].join(", ");
        }
    }

    async removeVykazXUser(vykazXUser: VykazXUser) {
        // skontrolujeme, ci mozme vymazat - skontrolujeme (priamo v DB), ci nejaky zapis nema tohto uzivatela zapisaneho v specPoradXUser/specPoradXUser2/psychPoradXUser/pracovnik1XUser/pracovnik2XUser
        if (vykazXUser.xUser) {
            if (!this.isAddRow()) {
                const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
                if (vykazStreetwork) {
                    // pre jednoduchost nacitame vsetky zaznamy Zapis
                    const zapisList: Zapis[] = await XUtils.fetchRows(
                        'Zapis',
                        {where: `[vykazStreetwork] = ${vykazStreetwork.id}`, params: {}},
                        undefined,
                        ["psychPoradXUser.name", "specPoradXUser.name", "specPoradXUser2.name", "pracovnik1XUser.name", "pracovnik2XUser.name", "klientSluzba.klient.menoPriezviskoPrezyvka"]);
                    for (const zapis of zapisList) {
                        if ((zapis.specPoradXUser && zapis.specPoradXUser.id === vykazXUser.xUser.id)
                            || (zapis.specPoradXUser2 && zapis.specPoradXUser2.id === vykazXUser.xUser.id)) {
                            alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v špecializovanom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                            return;
                        }
                        if (zapis.psychPoradXUser && zapis.psychPoradXUser.id === vykazXUser.xUser.id) {
                            alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný v psychologickom poradenstve na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                            return;
                        }
                        if ((zapis.pracovnik1XUser && zapis.pracovnik1XUser.id === vykazXUser.xUser.id)
                            || (zapis.pracovnik2XUser && zapis.pracovnik2XUser.id === vykazXUser.xUser.id)) {
                            alert(`Nie je dovolené vymazať pracovníka ${vykazXUser.xUser.name}, pracovník je zapísaný na zázname pre klienta ${zapis.klientSluzba?.klient?.menoPriezviskoPrezyvka}`);
                            return;
                        }
                    }
                }
            }
        }

        // default remove
        this.onTableRemoveRow("vykazXUserList", vykazXUser);
    }

    zobrazitZapisy() {
        const vykazStreetwork: VykazStreetwork | null = this.getVykazStreetwork();
        if (vykazStreetwork) {
            const filters: DataTableFilterMeta = {};
            const datum: Date | null = dateFromModel(vykazStreetwork.datum);
            if (datum && vykazStreetwork.tim) {
                if (!XUtils.isMobile()) {
                    filters.datum = {value: [datum, datum], matchMode: FilterMatchMode.BETWEEN};
                }
                else {
                    // mobil ma jednoduchy datum
                    filters.datum = {value: datum, matchMode: FilterMatchMode.EQUALS};
                }
                filters["vykazStreetwork.tim.name"] = {value: vykazStreetwork.tim.name, matchMode: FilterMatchMode.EQUALS};
                // openForm pridavame automaticky v XFormNavigator3 pri renderovani komponentu (ak neotvarame form v dialogu (ak ho otvarame cez openForm))
                (this.props as XBrowseProps).openForm!(<ZapisBrowse filters={filters} stateKey="vykaz-streetwork-hlavicka-zapisy"/>);
            }
        }
    }

    // overrides method in XFormBase
    async validate(vykazStreetwork: VykazStreetwork): Promise<XErrors> {
        const errors: XErrors = {};

        // okontrolujeme, ci nevyrabame duplicitny zaznam (nedalo sa nahodit unique constraint koli datam z migracie)
        const datum: Date | null = vykazStreetwork.datum;
        const tim: XEnum | null = vykazStreetwork.tim;
        if (datum && tim) {
            let vykazStreetworkList: any[] = await XUtils.fetchRows('VykazStreetwork',
                {where: "[datum] = :datum AND [tim] = :timId AND [id] <> :vykazStreetworkId",
                    params: {datum: datum, timId: tim.id, vykazStreetworkId: vykazStreetwork.id ?? 0}});
            if (vykazStreetworkList.length > 0) {
                errors.tim = `Už existuje záznam s dátumom ${dateAsUI(dateFromModel(datum))} a tímom ${tim.name}.`;
            }
        }

        // okontrolujeme, ci nenastala situacia, ze niekto zavesil zapis na hlavicku a uzivatel zaroven zmenil datum
        if (!this.isAddRow()) {
            if (vykazStreetwork.datum) {
                const zapisList: Zapis[] = await XUtils.fetchRows(
                    'Zapis',
                    {where: `[vykazStreetwork] = ${vykazStreetwork.id} AND [datum] <> :datum`, params: {datum: dateFromModel(vykazStreetwork.datum)}});
                if (zapisList.length > 0) {
                    errors.datum = `Nie je dovolené zmeniť dátum, lebo denník už obsahuje aspoň 1 zápis s dátumom ${dateAsUI(dateFromModel(zapisList[0].datum))}`;
                }
            }
        }

        return errors;
    }

    renderDesktop() {
        return (
            <div>
                <XFormScrollable form={this} headerFooterHeight="8rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <div className="x-form-row">
                                <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'6rem'}} readOnly={this.readOnlyDatum}/>
                                <XFormHeader form={this} label="Denník" style={{marginTop: '0rem', marginBottom: '1rem', marginLeft: '2rem', marginRight: '1.5rem'}}/>
                                <div className="x-form-inline-row">
                                    <XInputDate form={this} field="modifDate" label="Modif." labelStyle={{width:'4.5rem'}} readOnly={true}/>
                                    <XInputText form={this} field="modifXUser.name" labelStyle={{width:'0rem'}} inputStyle={{width:'10rem'}} readOnly={true}/>
                                    {!this.isAddRow() ? <XInputDecimal form={this} field="id" labelStyle={{width:'0rem'}} inputStyle={{width:'4rem'}} readOnly={true}/> : null}
                                </div>
                            </div>
                            <div className="x-form-row">
                                <div className="x-form-col">
                                    <XAutoComplete form={this} assocField="palivoVIgnise" displayField="name" label="Palivo v Ignise" labelStyle={{width:'9.5rem'}}
                                                   filter={Utils.enumFilter(EnumEnum.palivoVIgnise)} sortField="enumOrder" width="8.35rem"/>
                                    <XAutoComplete form={this} assocField="tim" displayField="name" label="Tím" labelStyle={{width:'9.5rem'}}
                                                   filter={Utils.enumFilter(EnumEnum.streetworkTim)} sortField="enumOrder" width="8.35rem"/>
                                    <XFormDataTable2 form={this} assocField="vykazXUserList"
                                                     onClickRemoveRow={this.removeVykazXUser}>
                                        <XFormColumn field="id" header="ID" readOnly={true} width="3rem" columnViewStatus={false}/>
                                        <XFormAutoCompleteColumn assocField="xUser" displayField="name" header="Pracovník" width="15rem"
                                                                 suggestions={this.userStreetworkList}/>
                                    </XFormDataTable2>
                                </div>
                                <div className="x-form-col">
                                    <XFormDataTable2 form={this} assocField="vykazMestskaCastList" label="Mestské časti - kilometre, hodiny">
                                        <XFormAutoCompleteColumn assocField="mestskaCast" displayField="name" header="Mestská časť" width="12rem" filter={Utils.enumFilter(EnumEnum.mestskaCast)}/>
                                        <XFormColumn field="kilometre" header="Kilometre" width="6rem"/>
                                        <XFormColumn field="hodinyCelkovo" header="Hodiny celkovo" width="8rem"/>
                                        <XFormColumn field="hodinyVTerene" header="Hodiny v teréne" width="8rem"/>
                                    </XFormDataTable2>
                                </div>
                            </div>{/* x-form-row pre stlpce */}
                            <XFormDataTable2 form={this} assocField="vykazMiestoMoznehoKontaktuList" label="Miesta možného kontaktu">
                                <XFormColumn field="miestoMoznehoKontaktu.mestskaCast.name" header="Mestská časť" readOnly={true} width="10rem"/>
                                <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField={["miesto", "mestskaCast.name"]} fields={["mestskaCast.name"]}
                                                         itemTemplate={this.itemTemplateMiestoMoznehoKontaktu}
                                                         suggestionsLoad="lazy" lazyLoadMaxRows={30}
                                                         header="Miesto možného kontaktu" width="24rem" scrollHeight="24rem"
                                                         filter={XUtilsCommon.filterAnd(Utils.filterCurrentSluzba(), {where: `[mestskaCast] IS NOT NULL`, params: {}})}
                                                         searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                <XFormColumn field="miestoMoznehoKontaktu.poznamka" header="Poznámka" readOnly={true} width="20rem"/>
                            </XFormDataTable2>
                            <XEditor form={this} field="priebehSluzby" label="Priebeh služby" labelOnTop={true} inputStyle={{width: '100%', maxWidth: '56rem'}}/>
                            {!this.isInDialog() ?
                                <div className="flex justify-content-center">
                                    {/*    <XButtonIconMedium icon="pi pi-check" onClick={() => this.addZapis()}/> */}
                                    <XButton label={`Zápisy (${this.state.object?.zapisList.length ? this.state.object?.zapisList.length : 0})`}
                                             disabled={this.state.object?.zapisList.length === 0}
                                             onClick={() => this.zobrazitZapisy()} style={{backgroundColor:'green'}}/>
                                </div>
                            : null}
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    // TODO - bolo by fajn pridat label + tlacitko na zobrazenie modif atributov (vedla datumu)
    renderMobile() {
        return (
            <div>
                <XFormHeader form={this} label="Denník"/>
                <XFormScrollable form={this} headerFooterHeight="11.5rem" widthFitContent={true}>
                    <div className="x-form-row">
                        <div className="x-form-col">
                            <XInputDate form={this} field="datum" label="Dátum" labelStyle={{width:'9.5rem'}} readOnly={this.readOnlyDatum}/>
                            <XAutoComplete form={this} assocField="palivoVIgnise" displayField="name" label="Palivo v Ignise" labelStyle={{width:'9.5rem'}}
                                           filter={Utils.enumFilter(EnumEnum.palivoVIgnise)} sortField="enumOrder" width="8.35rem"/>
                            <XAutoComplete form={this} assocField="tim" displayField="name" label="Tím" labelStyle={{width:'9.5rem'}}
                                           filter={Utils.enumFilter(EnumEnum.streetworkTim)} sortField="enumOrder" width="8.35rem"/>
                            <XFormDataTable2 form={this} assocField="vykazXUserList"
                                             onClickRemoveRow={this.removeVykazXUser}>
                                <XFormColumn field="id" header="ID" readOnly={true} width="3rem" columnViewStatus={false}/>
                                <XFormAutoCompleteColumn assocField="xUser" displayField="name" header="Pracovník" width="15rem"
                                                         suggestions={this.userStreetworkList}/>
                            </XFormDataTable2>
                            <div style={{height: '1rem'}}/>
                            <XFormDataTable2 form={this} assocField="vykazMestskaCastList" label="Mestské časti - kilometre, hodiny">
                                <XFormAutoCompleteColumn assocField="mestskaCast" displayField="name" header="Mestská časť" width="12rem" filter={Utils.enumFilter(EnumEnum.mestskaCast)}/>
                                <XFormColumn field="kilometre" header="Km" width="3rem"/>
                                <XFormColumn field="hodinyCelkovo" header="Hod celk." width="5rem"/>
                                <XFormColumn field="hodinyVTerene" header="Hod terén" width="5rem"/>
                            </XFormDataTable2>
                            <div style={{height: '1rem'}}/>
                            <XFormDataTable2 form={this} assocField="vykazMiestoMoznehoKontaktuList" label="Miesta možného kontaktu">
                                <XFormColumn field="miestoMoznehoKontaktu.mestskaCast.name" header="Mestská časť" readOnly={true} width="8rem"/>
                                <XFormAutoCompleteColumn assocField="miestoMoznehoKontaktu" displayField={["miesto", "mestskaCast.name"]} fields={["mestskaCast.name"]}
                                                         itemTemplate={this.itemTemplateMiestoMoznehoKontaktu}
                                                         suggestionsLoad="lazy" lazyLoadMaxRows={30}
                                                         header="Miesto možného kontaktu" width="20rem" scrollHeight="20rem"
                                                         filter={XUtilsCommon.filterAnd(Utils.filterCurrentSluzba(), {where: `[mestskaCast] IS NOT NULL`, params: {}})}
                                                         searchBrowse={<MiestoMoznehoKontaktuBrowse/>} assocForm={<MiestoMoznehoKontaktuForm/>}/>
                                <XFormColumn field="miestoMoznehoKontaktu.poznamka" header="Poznámka" readOnly={true} width="20rem"/>
                            </XFormDataTable2>
                            <XEditor form={this} field="priebehSluzby" label="Priebeh služby" labelOnTop={true}/>
                            {!this.isInDialog() ?
                                <div className="flex justify-content-center">
                                    {/*    <XButtonIconMedium icon="pi pi-check" onClick={() => this.addZapis()}/> */}
                                    <XButton label={`Zápisy (${this.state.object?.zapisList.length ? this.state.object?.zapisList.length : 0})`}
                                             disabled={this.state.object?.zapisList.length === 0}
                                             onClick={() => this.zobrazitZapisy()} style={{backgroundColor:'green'}}/>
                                </div>
                                : null}
                        </div>
                    </div>
                </XFormScrollable>
                <XFormFooter form={this}/>
            </div>
        );
    }

    render() {
        if (!XUtils.isMobile()) {
            return this.renderDesktop();
        } else {
            return this.renderMobile();
        }
    }
}
