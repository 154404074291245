import React from "react";
import {SelectButton, SelectButtonChangeEvent} from "primereact/selectbutton";
import {XDisplayType} from "./XFieldSetBase";

// switch to display/hide fields in XFieldSetBase

interface Option {
    icon: string;
    value: XDisplayType;
}

export const XDisplayTypeSwitch = (props: {
    value: XDisplayType;
    onChange: (value: XDisplayType) => void;
    className?: string;
}) => {

    const options: Option[] = [
        {icon: 'pi pi-minus', value: XDisplayType.labelsOnly},
        {icon: 'pi pi-chevron-left', value: XDisplayType.groupsColapsed},
        {icon: 'pi pi-chevron-right', value: XDisplayType.groupsExpanded}
    ];

    const itemTemplate = (option: Option) => {
        return <i className={option.icon}></i>;
    }

    return (
        <SelectButton value={props.value} onChange={(e: SelectButtonChangeEvent) => props.onChange(e.value)}
                      options={options} optionValue="value" itemTemplate={itemTemplate} allowEmpty={false} className={props.className}/>
    );
}
