import {XFormBaseModif} from "@michalrakus/x-react-web-lib/XFormBaseModif";
import {SluzbaRolaPrava} from "../../model/user/sluzba-rola-prava.entity";
import {XUtils} from "@michalrakus/x-react-web-lib/XUtils";
import {Utils} from "../../Utils";
import {XUserDePaul} from "../../model/user/x-user-de-paul.entity";
import {XFormProps} from "@michalrakus/x-react-web-lib/XFormBase";
import {Rola, SluzbaEnum} from "../../common/enums";
import {FindUsersRequest} from "../../common/user-api";
import {XUtilsCommon} from "@michalrakus/x-react-web-lib/XUtilsCommon";

// spolocna nadtrieda pre formy obsahujuce ZapisPanel
export class ZapisBaseForm extends XFormBaseModif {

    // casom mozno pojde prec, bude nahradene lazy
    userPsychPoradList: XUserDePaul[] = [];
    userSpecPoradList: XUserDePaul[] = [];
    userList: XUserDePaul[] = [];

    constructor(props: XFormProps) {
        super(props);

        this.onChangeForm = this.onChangeForm.bind(this);
        this.onEditFieldChange = this.onEditFieldChange.bind(this);
    }

    async componentDidMount() {
        await super.componentDidMount();

        const sluzbaKod: SluzbaEnum = Utils.getCurrentSluzba()!.kod as SluzbaEnum;
        // nacitame si zoznam userov ktori mozu robit spec/psych poradenstvo (tych budeme ponukat v prislusnych autocomplete-och)
        this.userPsychPoradList = await XUtils.fetchMany('find-users', {sluzbaKod: sluzbaKod, rolaCode: Rola.psychPoradPracovnik} satisfies FindUsersRequest);
        this.userSpecPoradList = await XUtils.fetchMany('find-users', {sluzbaKod: sluzbaKod, rolaCode: Rola.specPoradPracovnik} satisfies FindUsersRequest);
        this.userList = await XUtils.fetchMany('find-users', {sluzbaKod: sluzbaKod} satisfies FindUsersRequest);

        await this.loadPrava();

        // koli efektivnosti volame az na koniec v podtriede ZapisForm/VykazStreetworkForm
        //this.setStateXForm(); // aby sa prejavili zmeny ktore zavisia od nacitanych zoznamov
    }

    async loadPrava() {
        const sluzbaRolaPravaList: SluzbaRolaPrava[] = await XUtils.fetchRows('SluzbaRolaPrava', undefined, undefined, ['typPrav.code']);
        const idSluzbaRolaPravaMap: Map<number, SluzbaRolaPrava> = XUtilsCommon.arrayCreateMap<number, SluzbaRolaPrava>(sluzbaRolaPravaList, 'id');
        // pre "natvrdo" zadane prava si vytvorime map kde key je kod
        const kodSluzbaRolaPravaMap: Map<string, SluzbaRolaPrava> = XUtilsCommon.arrayCreateMap<string, SluzbaRolaPrava>(sluzbaRolaPravaList.filter((value: SluzbaRolaPrava) => value.kod !== null), 'kod');
        // map zapisujeme do state aby sa v pripade rereadu (cez onEditFieldChange) zmeny rozhlasili do child komponentu (mozno by stacilo zavolat this.setStateXForm();)
        this.setState({idSluzbaRolaPravaMap: idSluzbaRolaPravaMap, kodSluzbaRolaPravaMap: kodSluzbaRolaPravaMap});
    }

    onChangeForm(callback?: () => void) {
        // zavolame react metodu this.setState (aby sa zmena prejavila vo formulari)
        this.setStateXForm(callback);
    }

    async onEditFieldChange() {
        // pocas editacie fieldset-u mohlo dojst k insertu noveho zaznamu prav, preto refreshneme zoznam prav (inac by aplikacia spadla)
        await this.loadPrava();
    }
}